import React from 'react'
import Construccion from '../Components/Construccion/Construccion'

const PageConstruccion = () => {
  return (
    <div>
      <Construccion />
    </div>
  )
}

export default PageConstruccion
